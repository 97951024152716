export default class TokenPermissionDecode {
  public getPermission(keyword: string, msg: string) {
    const initialTokenDecode = atob(msg.split(".")[1]); //this gets the object from the token string
    let permission: string | RegExpMatchArray | null = initialTokenDecode.match(
      /{"per":{(.*)},/g
    ); //this pulls out the "per" key and removes the un-needed parts
    if (permission) {
      permission = permission[0].replace(/{"per":/g, ""); //gets only the first match and removes everything outside the per key
      permission = permission.substring(0, permission.length - 1); //removes the ending character "," leftover from original object
      permission = permission.replace(/(\d{5,})/g, '"$1"'); //wraps all numbers longer than 5 digits long in quotations

      const permissionTemp = BigInt(JSON.parse(permission)[keyword]); //gets the ucportal key from the newly formatted json, uses bigInt lib to deal with string numbers;
      const permissionArr = permissionTemp
        .toString(2)
        .split("")
        .reverse(); //this splits it into an array and then reverses the array
      return permissionArr;
    }
  }
}
