import axios from "axios";
import store from "@/store";
import * as Type from "@/types";
import tokenPermissionDecode from "@/assets/ts/tokenPermissionDecode";
import DocumentationHandler from "@/assets/ts/documentationHandler.ts";
import dayjs, { Dayjs } from "dayjs";

const tokenPermDecode = new tokenPermissionDecode();
const docHandler = new DocumentationHandler();

//Uppercase A Axios instances use the default baseurl and permissions. Lowercase a instances pull settings from the config file.
const Axios = axios.create();

async function getConfig() {
  return (await axios.get("static/config.json")).data;
}

Axios.interceptors.request.use(async req => {
  //allowing a full url to go through without change.
  const fullUrlExpression = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi
  );
  const configLocation = "static/config.json";
  if (
    Axios.defaults.baseURL == undefined &&
    req.url != configLocation &&
    !req.url!.match(fullUrlExpression)
  ) {
    const config = await getConfig();
    Axios.defaults.baseURL = config.apiUrl;
    req.url = config.apiUrl + req.url;
    if (Axios.defaults.headers["Authorization"] === undefined) {
      const token = store.getters.userInfo.token;
      Axios.defaults.headers["Authorization"] = `Bearer ${token}`;
      req.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return req;
});

export default class RequestHandler {
  public async refreshToken(token: string): Promise<void> {
    const response = (await Axios.get("/auth/refresh")).data;
    store.dispatch("setNewToken", response.token);
    Axios.defaults.headers["Authorization"] = `Bearer ${response.token}`;
  }
  public async createAccount(
    params: Type.NewAccount
  ): Promise<Type.NewAccount> {
    try {
      const config = await getConfig();
      return (await axios.post(`${config.apiUrl}/mvds/customer`, params)).data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async addNewEmployee(body: Type.NewEmployee, mvdsCustomerID: number) {
    await Axios.post(`mvds/customer/${mvdsCustomerID}/user`, body);
  }
  public async uploadDocument(
    mvdsCustomerID: number,
    file: any,
    fileName: string,
    useToken = true
  ) {
    try {
      let fileContent = await docHandler.getBase64(file);
      const commaPos = fileContent.indexOf(",");
      fileContent = fileContent.slice(commaPos + 1);
      const params = {
        filename: fileName,
        mvdsCustomerID: mvdsCustomerID,
        statusCode: "A",
        contents: fileContent
      };
      if (!useToken) {
        const config = await getConfig();
        return (await axios.post(`${config.apiUrl}/mvds/document`, params))
          .data;
      } else {
        return (await Axios.post(`/mvds/document`, params)).data;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async retrieveDocumentDirectory(
    mvdsCustomerID: number,
    fileName: string
  ) {
    try {
      const response = (
        await Axios.get(
          `/mvds/customer/${mvdsCustomerID}/document/${fileName}/history?maxCount=5`
        )
      ).data;
      response.forEach((element: any) => {
        element.contents =
          "data:" + element.fileType + ";base64," + element.contents;
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async downloadDocument(mvdsCustomerID: number, fileName: string) {
    try {
      const response = (
        await Axios.get(`/mvds/customer/${mvdsCustomerID}/document/${fileName}`)
      ).data;

      return "data:" + response.fileType + ";base64," + response.contents;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async checkForExistence(type: string, item: string) {
    const config = await getConfig();
    if (type === "username") {
      return (
        await axios.get(`${config.apiUrl}/mvds/exists?userPartner=${item}@mvds`)
      ).data;
    } else if (type === "email") {
      return (await axios.get(`${config.apiUrl}/mvds/exists?email=${item}`))
        .data;
    } else if (type === "businessName") {
      return (
        await axios.get(`${config.apiUrl}/mvds/exists?customerName=${item}`)
      ).data;
    }
    return false;
  }
  public async login(params: Type.LoginParams): Promise<void> {
    try {
      if (!params.username.includes("@net.ads.state.tn.us")) {
        params.username = params.username + "@mvds";
      }
      const config = await getConfig();
      const response = (
        await axios.post(`${config.apiUrl}/mvds/user/login`, params)
      ).data;

      response.isLoggedIn = true;
      Axios.defaults.headers["Authorization"] = `Bearer ${response.token}`;
      const permArr = tokenPermDecode.getPermission("mvds", response.token);
      if (permArr) {
        if (permArr[1] === "1") {
          response.accountType = "Administrator";
        } else if (permArr[2] === "1" && permArr[5] === "1") {
          response.accountType = "Employer";
        } else if (permArr[2] === "1") {
          response.accountType = "EmployerEdit";
        } else {
          response.accountType = "Employee";
        }

        store.dispatch("setUserInfo", response);
        localStorage.setItem("userInfo", JSON.stringify(response));
      } else {
        throw "PermissionsError";
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async confirmPassword(params: Type.LoginParams) {
    params.username = params.username + "@mvds";
    const apiConfig = await getConfig();
    return (await axios.post(`${apiConfig.apiUrl}/login`, params)).data;
  }
  public async updatePassword(userID: number, password: string, token: string) {
    const apiConfig = await getConfig();
    const params = {
      userID: userID,
      newPassword: password,
      confirmPassword: password
    };
    const endpointConfig = { headers: { Authorization: "bearer " + token } };
    return (
      await axios.post(
        `${apiConfig.apiUrl}/auth/password-reset`,
        params,
        endpointConfig
      )
    ).data;
  }
  public async sendForgotPasswordEmail(username: string) {
    const config = await getConfig();
    return (
      await axios.get(
        `${config.apiUrl}/forgot/user/${username}@mvds/passwordreset/guid/email`
      )
    ).data;
  }
  public async sendForgotUsernameEmail(email: string) {
    const config = await getConfig();
    return (
      await axios.get(
        `${config.apiUrl}/forgot/emailaddress/${email}/partner/mvds/username`
      )
    ).data;
  }
  public async resetForgotPassword(
    username: string,
    confirmPassword: string,
    newPassword: string,
    guid: string
  ) {
    const config = await getConfig();
    const params = {
      newPassword: newPassword,
      confirmPassword: confirmPassword
    };
    return (
      await axios.post(
        `${config.apiUrl}/forgot/user/${username}@mvds/passwordreset/guid/${guid}/reset`,
        params
      )
    ).data;
  }
  public async getUserCustomerInfo(userID: number): Promise<any> {
    return (await Axios.get("/mvds/user/" + userID + "/customer")).data;
  }
  public async getUserInfo(userID: number) {
    return (await Axios.get(`/mvds/user/${userID}`)).data;
  }
  public async logout(token: string): Promise<void> {
    try {
      await Axios.post("/logout");
    } catch (e) {
      console.error(e);
    } finally {
      Axios.defaults.headers["Authorization"] = undefined;
      store.dispatch("setDefaultState");
      localStorage.removeItem("userInfo");
    }
  }
  public async blackListToken(token: string) {
    const apiConfig = await getConfig();
    const endpointConfig = { headers: { Authorization: "bearer " + token } };
    return await axios.post(`${apiConfig.apiUrl}/logout`, "", endpointConfig);
  }
  public async getCustomer(mvdsCustomerID: number) {
    return (await Axios.get(`mvds/customer/${mvdsCustomerID}`)).data;
  }
  public async getAccounts(
    accountType: string
  ): Promise<{ [key: string]: string }[]> {
    return (await Axios.get("/mvds/customers?statusCodes=" + accountType)).data;
  }
  public async handleAccount(
    id: string,
    currStatus: string,
    newStatus: string,
    displayDesc = "",
    lookupType = "",
    lookupCode = ""
  ): Promise<{ [key: string]: string }[]> {
    if (displayDesc === "") {
      return (
        await Axios.post(`/mvds/customer/${id}/${currStatus}/${newStatus}`)
      ).data;
    } else {
      return await Axios.post(
        `/mvds/customer/${id}/${currStatus}/${newStatus}?displayDesc=${displayDesc}&lookupType=${lookupType}&lookupCode=${lookupCode}`
      );
    }
  }
  //TODO: It may be better to merge this function with handleAccount()
  //  above now that both use a bearer token
  public async updateAccountStatusNonAdmin(
    id: string,
    status: string,
    displayDesc = ""
  ): Promise<{ [key: string]: string }[]> {
    return (
      await Axios.post(
        `/mvds/customer/${id}/${status}?displayDesc=${displayDesc}`
      )
    ).data;
  }
  public async handleEmployee(id: string, status: string) {
    return (await Axios.post(`/mvds/user/${id}/${status}`)).data;
  }
  public async getStatusCodeReasons(): Promise<{ [key: string]: string }[]> {
    return (await Axios.get("/mvds/statuscode/reasons")).data;
  }
  public async getVehicle(
    vin: string,
    plateNo = "",
    creditCard = {}
  ): Promise<any> {
    try {
      const response = (
        await Axios.post("/mvds/search", {
          vin,
          plateNo,
          creditCard
        })
      ).data;

      if (response.vehicleRecord) {
        store.dispatch("setUserInfo", response);
      }
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getStates() {
    try {
      return (await axios({ url: "./States.json" })).data;
    } catch (e) {
      return Promise.reject(e.message);
    }
  }
  public async getCustomerTypes() {
    const config = await getConfig();
    return (await axios.get(config.apiUrl + "/mvds/customertypes")).data;
  }
  public async getSearchHistory() {
    let beginDate = dayjs();
    let endDate = dayjs();
    endDate = endDate.add(1, "days");
    beginDate = endDate.subtract(30, "days");
    return (
      await Axios.get(
        `/mvds/searches/${beginDate.format("YYYY-MM-DD")}/${endDate.format(
          "YYYY-MM-DD"
        )}`
      )
    ).data;
  }
  public async getSearchesRange(
    mvdsCustomerID: number,
    beginDate: string,
    endDate: string
  ) {
    return (
      await Axios.get(
        `/mvds/customer/${mvdsCustomerID}/searches/${beginDate}/${endDate}`
      )
    ).data;
  }
  public async getAllSearches(mvdsCustomerID: number) {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    return (
      await Axios.get(
        `/mvds/customer/${mvdsCustomerID}/searches/0001-01-01/${endDate.toLocaleDateString(
          "fr-CA"
        )}`
      )
    ).data;
  }
  public async getPreviousSearch(searchID: number): Promise<any> {
    return (await Axios.get(`/mvds/search/${searchID}`)).data;
  }
  public async getTermsAndConditions() {
    try {
      return (await axios({ url: "./TermsAndConditions.json" })).data[0][
        "text"
      ];
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async validateRoutingNo(routingNo: string): Promise<any> {
    return (await axios.get("https://api.bisonline.com/routingno/" + routingNo))
      .data;
  }
  public async editCustomer(account: object): Promise<any> {
    return (await Axios.post("/mvds/customer", account)).data;
  }
  public async editUser(mvdsCustomerID: number, account: object): Promise<any> {
    return (await Axios.post(`mvds/customer/${mvdsCustomerID}/user`, account))
      .data;
  }
  public async getEmployees(mvdsCustomerID: number) {
    return (await Axios.get(`mvds/customer/${mvdsCustomerID}/users`)).data;
  }
  public async getNCICHits() {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    return (
      await Axios.get(
        `/mvds/searches/0001-01-01/${endDate.toLocaleDateString(
          "fr-CA"
        )}?statusCodes=H,S`
      )
    ).data;
  }
  public async getStolenVehicleLetter(searchID: number): Promise<string> {
    return (await Axios.get(`mvds/search/${searchID}/pdf/stolenvehicle`)).data;
  }
  public async getOwnershipLetter(searchID: number) {
    return (await Axios.get(`mvds/search/${searchID}/pdf/proofofownership`))
      .data;
  }
  public async getActiveVehicleForm(searchID: number) {
    return (await Axios.get(`mvds/search/${searchID}/pdf/activevehicle`)).data;
  }
  public async completeNicReport(params: Type.NICParams) {
    return (await Axios.post(`mvds/search/niccomplete`, params)).data;
  }
  public async getMVDSSeachFee() {
    return (await Axios.get("/mvds/fee/fees?feename=MVDS Search Fee")).data;
  }
  public async getFISConvenienceFee() {
    return (await Axios.get("/mvds/fee/fees?feename=FIS Convenience Fee")).data;
  }
  public getSmartyStreetsConfig() {
    const config = getConfig();
    return {
      smartyStreetsWebsiteKey: "5563674446620868",
      smartyConfig: {
        maxSuggestions: 10,
        prefer: ["TN"],
        preferRatio: 1.0,
        geolocate: true
      }
    };
  }
}
