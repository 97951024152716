





















import Vue from "vue";
import HeaderBar from "./components/HeaderBar.vue";
import FooterBar from "./components/FooterBar.vue";
import Snackbar from "./components/Snackbar.vue";
import RequestHandler from "@/assets/ts/requestHandler.ts";

/* Adding comment for semantic-release test. */

const RH = new RequestHandler();

export default Vue.extend({
  name: "App",
  components: {
    HeaderBar,
    FooterBar,
    Snackbar
  },
  data: () => ({
    footerLinksLeft: [
      {
        text: "TN.gov Services",
        href: "https://www.tn.gov/state-services.html"
      },
      {
        text: "TN.gov Directory",
        href: "https://www.tn.gov/directory.html"
      },
      {
        text: "Transparent TN",
        href: "https://www.tn.gov/transparenttn.html"
      },
      {
        text: "Privacy Policy",
        route: "PrivacyPolicy"
      }
    ],
    footerLinksRight: [
      {
        text: "About Tennessee",
        href: "https://www.tn.gov/about-tn/state-symbols.html"
      },
      {
        text: "Title VI",
        href:
          "https://www.tn.gov/humanrights/file-a-discrimination-complaint/title-vi1/title-vi-disclaimer.html"
      },
      {
        text: "Accessibility",
        href: "https://www.tn.gov/web-policies/accessibility.html"
      },
      {
        text: "Help & FAQs",
        route: "FAQs"
      },
      {
        text: "Survey",
        href: "https://www.tn.gov/survey.html"
      }
    ],
    pollTokenTimer: 0
  }),
  computed: {
    packageVersion: function() {
      return "v" + process.env.VUE_APP_VERSION;
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    }
  },
  watch: {
    isIdle: async function(userIsIdle) {
      if (this.$store.getters.userInfo.isLoggedIn && userIsIdle) {
        try {
          await RH.logout(this.$store.getters.userInfo.token);
          this.$root.$emit(
            "snackbar-message",
            "You have been logged out due to inactivity"
          );
          this.$router.push({ name: "Login" });
        } catch (e) {
          this.$root.$emit("snackbar-message", "Inactivity logout failure");
        }
      }
    }
  },
  created() {
    this.pollTokenTimer = window.setInterval(async () => {
      if (this.$store.getters.getTokenExpirationTime === undefined) {
        return;
      }
      const tokenExpirationDate = new Date(
        this.$store.getters.getTokenExpirationTime * 1000
      );
      const minuteDifference = Math.floor(
        (tokenExpirationDate.getTime() - new Date().getTime()) / 1000 / 60
      );
      if (minuteDifference <= 30) {
        await RH.refreshToken(this.$store.getters.userInfo.token);
      }
    }, 300000);
  },
  beforeDestroy() {
    clearInterval(this.pollTokenTimer);
  }
});
