import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import RequestHandler from "@/assets/ts/requestHandler.ts";

const RH = new RequestHandler();

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    alias: "/",
    name: "Login",
    component: require("../views/Login.vue").default,
    meta: {
      name: "Login"
    }
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/views/Signup.vue"),
    meta: {
      name: "Signup"
    }
  },
  {
    path: "/forgotUserPass",
    name: "ForgotUserPass",
    component: () => import("@/views/ForgotUserPass.vue"),
    meta: {
      name: "ForgotUserPass"
    }
  },
  {
    path: "/faqs",
    name: "FAQs",
    component: () => import("@/views/FAQs.vue"),
    meta: {
      name: "FAQs"
    }
  },
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue"),
    meta: {
      name: "PrivacyPolicy"
    }
  },
  {
    path: "/passwordReset/:guid",
    name: "PasswordReset",
    component: () => import("@/views/PasswordReset.vue"),
    meta: {
      name: "PasswordReset"
    }
  },
  {
    path: "/administration",
    name: "Administration",
    component: () => import("@/views/Administration.vue"),
    meta: {
      name: "Administration",
      auth: {
        requiresLogin: true,
        permission: ["Administrator"]
      }
    }
  },
  {
    path: "/editUserAccount",
    name: "EditUserAccount",
    component: () => import("@/views/EditUserAccount.vue"),
    meta: {
      name: "EditUserAccount",
      auth: {
        requiresLogin: true,
        permission: ["Employer"]
      }
    }
  },
  {
    path: "/editMode",
    name: "EditMode",
    component: () => import("@/views/EditMode.vue"),
    meta: {
      name: "EditMode",
      auth: {
        requiresLogin: true,
        permission: ["EmployerEdit"]
      }
    }
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/Search.vue"),
    meta: {
      name: "Search",
      auth: {
        requiresLogin: true,
        permission: ["Employer", "Employee"]
      }
    }
  },
  {
    path: "/searchResults",
    name: "SearchResults",
    component: () => import("@/views/SearchResults.vue"),
    meta: {
      name: "SearchResults",
      auth: {
        requiresLogin: true,
        permission: ["Employer", "Employee"]
      }
    }
  },
  {
    path: "/noResults/:vehicle",
    name: "NoResults",
    component: () => import("@/views/NoResults.vue"),
    meta: {
      name: "NoResults",
      auth: {
        requiresLogin: true,
        permission: ["Employer", "Employee"]
      }
    }
  },
  {
    path: "/employeeManagement",
    name: "EmployeeManagement",
    component: () => import("@/views/EmployeeManagement.vue"),
    meta: {
      name: "EmployeeManagement",
      auth: {
        requiresLogin: true,
        permission: ["Employer"]
      }
    }
  },
  {
    path: "/ncicHits",
    name: "NCICHits",
    component: () => import("@/views/NCICHits.vue"),
    meta: {
      name: "NCICHits",
      auth: {
        requiresLogin: true,
        permission: ["Administrator"]
      }
    }
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("@/views/Reports.vue"),
    meta: {
      name: "Reports",
      auth: {
        requiresLogin: true,
        permission: ["Employer"]
      }
    }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  if (Object.keys(userInfo).length !== 0) {
    store.dispatch("setUserInfo", userInfo);
  }

  if (to.name != null) {
    if (
      to.meta!.auth &&
      to.meta!.auth.requiresLogin &&
      !store.getters.userInfo.isLoggedIn
    ) {
      next({
        path: "/login"
      });
    } else if (!to.meta!.auth) {
      if (store.getters.userInfo.isLoggedIn) {
        RH.logout(store.getters.userInfo.token);
      }
      next();
    } else {
      if (
        to.meta!.auth.permission.includes(store.getters.userInfo.accountType)
      ) {
        next();
      }
    }
  }
});

export default router;
