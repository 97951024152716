import Vue from "vue";
import Vuex from "vuex";
import pathify from "vuex-pathify";

import userInfo from "./userInfo";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: {
    userInfo
  }
});

