<template>
  <footer id="footer">
    <div id="footer-top">
      <a :href="starBallLink" class="starball">
        <img
          src="https://www.tn.gov/assets/img/footer-starball.png"
          alt="Tristar"
        />
      </a>
    </div>
    <div id="footer-bottom">
      <div class="row">
        <template v-if="this.$vuetify.breakpoint.name != 'xs'">
          <div
            v-for="column in 2"
            :key="column"
            :class="{ 'text-right': column === 2 }"
          >
            <ul class="inline-list">
              <li
                v-for="element in column === 1 ? linksLeft : linksRight"
                :key="element.text"
              >
                <template v-if="element.href">
                  <a :href="element.href">
                    {{ element.text }}
                  </a>
                </template>
                <template v-else-if="element.route">
                  <router-link :to="element.route">
                    {{ element.text }}
                  </router-link>
                </template>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <ul class="inline-list xsLinks">
            <li v-for="element in linksLeft" :key="element.text">
              <template v-if="element.href">
                <a :href="element.href">
                  {{ element.text }}
                </a>
              </template>
              <template v-else-if="element.route">
                <router-link :to="element.route">
                  {{ element.text }}
                </router-link>
              </template>
            </li>
            <li v-for="element in linksRight" :key="element.text">
              <template v-if="element.href">
                <a :href="element.href">
                  {{ element.text }}
                </a>
              </template>
              <template v-else-if="element.route">
                <router-link :to="element.route">
                  {{ element.text }}
                </router-link>
              </template>
            </li>
          </ul>
        </template>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    linksLeft: Array,
    linksRight: Array
  },
  data() {
    return {
      starBallLink: "https://help.tn.gov/tn?id=tn_index"
    };
  }
};
</script>

<style scoped lang="scss">
#footer {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 0px;
}

.starball img {
  max-width: 100%;
}

.row {
  margin: 0 auto;
  display: block;
}

.row > div {
  display: inline-block;
}

.row > div:last-child {
  float: right;
}

ul {
  padding: 0;
}

.xsLinks {
  text-align: center;
  margin-left: -20px;
}
</style>
