













import { Component, Vue } from "vue-property-decorator";

@Component
export default class Snackbar extends Vue {
  private display = false;
  private text = "";
  private timeout = 30000;

  created() {
    this.$root.$on("snackbar-message", (messageText: string) => {
      this.text = messageText;
      this.display = true;
    });
  }
}
