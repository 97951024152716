export default class DocumentationHandler {
  public getBase64(file: Blob): Promise<any> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }

  public checkForWordDoc(base64: string) {
    const colonLocation = base64.indexOf(":");
    const semiColonLocation = base64.indexOf(";");
    const fileType = base64.slice(colonLocation + 1, semiColonLocation);
    return fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "application/msword"
      ? true
      : false;
  }
}
