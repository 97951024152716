











































































































































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";

const RH = new RequestHandler();

@Component
export default class Login extends Vue {
  private username = "";
  private password = "";
  private showPass = false;
  private errorMessage = "";
  private forgotPasswordDialog = false;
  private forgotTypeBoxItems = ["Username", "Password"];

  private async login(username: string, password: string): Promise<void> {
    this.errorMessage = "";

    try {
      await RH.login({
        username,
        password
      });

      if (this.$store.getters.userInfo.accountType === "Administrator") {
        this.$router.replace({ name: "Administration" });
      } else if (this.$store.getters.userInfo.accountType === "EmployerEdit") {
        this.$router.replace({ name: "EditMode" });
      } else {
        this.$router.replace({ name: "Search" });
      }
    } catch (e) {
      if (e.response === undefined) {
        this.errorMessage = "Invalid server response. Please try again.";
        return;
      }

      switch (e.response.status) {
        case 401:
          this.errorMessage = e.response.data.err;
          break;
        case 404:
          this.errorMessage = "Invalid credentials. Please try again.";
          break;
        default:
          this.errorMessage = "Invalid server response. Please try again.";
          break;
      }
    }
  }
}
