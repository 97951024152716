import { commit, make, Payload } from "vuex-pathify";
import JSONBigInt from "json-bigint";

const defaultState = () => {
  return {
    userInfo: {
      isLoggedIn: false,
      token: "",
      refreshToken: "",
      username: "",
      sessionStartTime: "",
      userID: 0,
      userFullName: "",
      partnerID: 0,
      partnerName: "",
      passwordExpiresAt: null,
      countyConfig: null,
      featureFlags: {},
      secondaryContexts: [],
      workstationId: 0,
      accountType: ""
    }
  };
};

const state = defaultState();

const mutations = {
  ...make.mutations(state)
};

const actions = {
  ...make.actions(state),
  setNewToken({ commit }: { commit: Function }, token: string) {
    commit("SET_USER_INFO", Object.assign({}, state.userInfo, { token }));
  },
  setDefaultState({ commit }: { commit: Function }) {
    commit("SET_USER_INFO", Object.assign({}, state, defaultState().userInfo));
  }
};

const getters = {
  ...make.getters(state),
  getTokenExpirationTime() {
    if (state.userInfo.isLoggedIn) {
      const jwtPayloadString = atob(state.userInfo.token.split(".")[1]);
      const jwtPayload = JSONBigInt.parse(jwtPayloadString);
      return jwtPayload.exp;
    } else {
      return undefined;
    }
  },
  getUserID() {
    return state.userInfo.userID;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
