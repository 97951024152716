import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#445c7c",
        secondary: "#1b365d",
        danger: "#de5766"
      }
    }
  }
};

export default new Vuetify(opts);
