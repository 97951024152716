

























































































































































































































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

const RH = new RequestHandler();
@Component
export default class HeaderBar extends Vue {
  private loading = false;
  private loadingOverlay = false;
  private emailAtLoad = "";
  private employeeEdit = {
    dialog: false,
    fullname: "",
    username: "",
    email: "",
    showOldPass: false,
    showNewPass: false,
    oldPassword: "",
    newPassword: "",
    valid: false,
    passwordAttempts: 0,
    loading: false
  };

  private rules = {
    required: (value: string | number) => !!value || "This field is required.",
    email: (value: string) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail address.";
    },
    oldPassword: (value: string) => {
      if (
        !(
          this.employeeEdit.oldPassword == "" &&
          this.employeeEdit.newPassword == ""
        ) &&
        !(this.employeeEdit.oldPassword && this.employeeEdit.newPassword)
      ) {
        return "Both password fields must be filled out or left blank.";
      }
      return true;
    },
    password: (value: string) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-])[A-Za-z\d~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-]{8,}$/;
      if (
        !(
          this.employeeEdit.oldPassword == "" &&
          this.employeeEdit.newPassword == ""
        )
      ) {
        if (!(this.employeeEdit.oldPassword && this.employeeEdit.newPassword)) {
          return "Both password fields must be filled out or left blank.";
        } else if (!pattern.test(value)) {
          return "Password must contain 1 lowercase letter, one uppercase letter, 1 number, 1 special character, and be at least 8 characters in total.";
        }
      }
      return true;
    }
  };

  get userItems() {
    return [
      { header: this.$store.getters.userInfo.username },
      {
        icon: "mdi-magnify",
        title: "Search",
        permission: ["Employer", "Employee"],
        action: () => {
          this.$router.push({ name: "Search" });
        }
      },
      {
        icon: "mdi-account-edit",
        title: "Edit Account",
        permission: ["Employer"],
        action: () => {
          this.$router.push({ name: "EditUserAccount" });
        }
      },
      {
        icon: "mdi-account-edit",
        title: "Edit Account",
        permission: ["EmployerEdit"],
        action: () => {
          this.$router.push({ name: "EditMode" });
        }
      },
      {
        icon: "mdi-account-edit",
        title: "Edit Account",
        permission: ["Employee"],
        action: () => {
          this.openEmployeeEditDialog();
        }
      },
      {
        icon: "mdi-file-document-edit",
        title: "Manage Employees",
        permission: ["Employer"],
        action: () => {
          this.$router.push({ name: "EmployeeManagement" });
        }
      },
      {
        icon: "mdi-file-document-multiple-outline",
        title: "Reports",
        permission: ["Employer"],
        action: () => {
          this.$router.push({ name: "Reports" });
        }
      },
      {
        icon: "mdi-account-edit",
        title: "Account Management",
        permission: ["Administrator"],
        action: () => {
          this.$router.push({ name: "Administration" });
        }
      },
      {
        icon: "mdi-table-alert",
        title: "Stolen Hits Grid",
        permission: ["Administrator"],
        action: () => {
          this.$router.push({ name: "NCICHits" });
        }
      },
      {
        icon: "mdi-logout",
        title: "Log Out",
        permission: ["Administrator", "Employer", "EmployerEdit", "Employee"],
        action: async () => {
          await RH.logout(this.$store.getters.userInfo.token);
          this.$router.push({ name: "Login" });
        }
      }
    ];
  }

  get employeeEditForm(): Vue & { validate: () => boolean } {
    return this.$refs.employeeEditForm as Vue & { validate: () => boolean };
  }

  private async openEmployeeEditDialog() {
    const employeeData = await RH.getUserInfo(
      this.$store.getters.userInfo.userID
    );
    this.employeeEdit.username = employeeData.username;
    this.employeeEdit.fullname = employeeData.fullname;
    this.emailAtLoad = employeeData.email;
    this.employeeEdit.email = employeeData.email;
    this.employeeEdit.dialog = true;
  }

  private async submitEmployeeEditForm() {
    if ((await this.emailIsUnique()) && this.validateEmployeeEditForm()) {
      this.editEmployee();
    }
  }

  private async emailIsUnique() {
    try {
      const emailExists = await RH.checkForExistence(
        "email",
        this.employeeEdit.email
      );
      if (emailExists && this.employeeEdit.email !== this.emailAtLoad) {
        this.$root.$emit(
          "snackbar-message",
          "Email is taken. Please choose another."
        );
        return false;
      } else {
        return true;
      }
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "Error checking to determine if email is unique."
      );
      return false;
    }
  }

  //TODO - This function should be replaced with direct calls to employeeEditForm.validate()
  private validateEmployeeEditForm(): boolean {
    return this.employeeEditForm.validate();
  }

  private async editEmployee() {
    this.employeeEdit.loading = true;
    const userID = this.$store.getters.userInfo.userID;
    const username = this.$store.getters.userInfo.username;
    const password = this.employeeEdit.oldPassword;
    const newPassword = this.employeeEdit.newPassword;
    let confirmationResponse = {} as any;
    let updatePassword = false;

    if (
      this.employeeEdit.oldPassword.length > 0 &&
      this.employeeEdit.newPassword.length > 0
    ) {
      updatePassword = true;
    }

    if (updatePassword) {
      try {
        confirmationResponse = await RH.confirmPassword({
          username,
          password
        });
      } catch (e) {
        this.employeeEdit.passwordAttempts++;
        if (this.employeeEdit.passwordAttempts > 4) {
          this.$root.$emit(
            "snackbar-message",
            "Maximum password attempts exceeded"
          );
          this.resetEmployeeEdit();
          this.employeeEdit.passwordAttempts = 0;
          await RH.logout(this.$store.getters.userInfo.token);
          this.$router.push({ name: "Login" });
          this.employeeEdit.loading = false;
          return;
        } else {
          this.$root.$emit("snackbar-message", "Old password is incorrect");
          this.employeeEdit.loading = false;
          return;
        }
      }
      this.employeeEdit.passwordAttempts = 0;
      try {
        await RH.updatePassword(
          userID,
          newPassword,
          confirmationResponse.token
        );
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Password and Account edit failed"
        );
        this.employeeEdit.loading = false;
        return;
      }
    }

    try {
      const employerMVDSCustomerID = (await RH.getUserCustomerInfo(userID))
        .mvdsCustomerID;
      const employeeBody = await RH.getUserInfo(userID);
      employeeBody.username = this.employeeEdit.username;
      employeeBody.email = this.employeeEdit.email;
      employeeBody.fullname = this.employeeEdit.fullname;

      await RH.editUser(employerMVDSCustomerID, employeeBody);
    } catch (e) {
      if (updatePassword) {
        this.$root.$emit(
          "snackbar-message",
          "Password edit was successful, but Account edit failed. Please log back in with your new password."
        );
        this.employeeEdit.loading = false;
        this.resetEmployeeEdit();
        await RH.logout(this.$store.getters.userInfo.token);
        this.$router.push({ name: "Login" });
      } else {
        this.$root.$emit("snackbar-message", "Account edit failed");
        this.employeeEdit.loading = false;
      }
      return;
    }

    if (updatePassword) {
      this.$root.$emit(
        "snackbar-message",
        "Password and Account edit were successful. Please log back in with your new password."
      );
      this.resetEmployeeEdit();
      await RH.logout(this.$store.getters.userInfo.token);
      this.$router.push({ name: "Login" });
    } else {
      this.$root.$emit(
        "snackbar-message",
        "Account edit submitted successfully"
      );
    }
    this.employeeEdit.loading = false;
    this.employeeEdit.dialog = false;
  }

  private resetEmployeeEdit() {
    this.employeeEdit.dialog = false;
    this.employeeEdit.fullname = "";
    this.employeeEdit.username = "";
    this.employeeEdit.email = "";
    this.employeeEdit.showOldPass = false;
    this.employeeEdit.showNewPass = false;
    this.employeeEdit.oldPassword = "";
    this.employeeEdit.newPassword = "";
    this.employeeEdit.valid = false;
  }

  created() {
    this.$root.$on("start-loading", () => {
      this.loading = true;
    });
    this.$root.$on("stop-loading", () => {
      this.loading = false;
    });
    this.$root.$on("start-loading-overlay", () => {
      this.loadingOverlay = true;
    });
    this.$root.$on("stop-loading-overlay", () => {
      this.loadingOverlay = false;
    });
  }
}
